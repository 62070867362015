// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

// Make mountApp function globally available
window.mountApp = (element, initialData = {}) => {
  // console.log('Mounting app with initial data:', initialData); // Debug log
  const root = createRoot(element);
  root.render(
    <React.StrictMode>
      <App initialData={initialData} />
    </React.StrictMode>
  );
};

// Auto-mount for development
if (process.env.NODE_ENV === 'development') {
  const rootElement = document.getElementById('root');
  if (rootElement) {
    window.mountApp(rootElement, { page: 'home' });
  }
}

// Keep the web vitals reporting
// reportWebVitals();