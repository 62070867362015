import React, { Suspense, useEffect, Component } from 'react';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';

// Lazy load components (keeping your existing imports)
const Home = React.lazy(() => import('./pages/home'));
const AboutUs = React.lazy(() => import('./pages/about'));
const Help = React.lazy(() => import('./pages/help'));
const Privacy = React.lazy(() => import('./pages/privacy'));
const Terms = React.lazy(() => import('./pages/terms'));
const Demo = React.lazy(() => import('./pages/demo'));
const Referral = React.lazy(() => import('./pages/referral'));
const Pricing = React.lazy(() => import('./pages/pricing'));
const Catalogs = React.lazy(() => import('./pages/catalogs'));
const Marketing = React.lazy(() => import('./pages/marketing'));
const Conversation = React.lazy(() => import('./pages/conversation'));
const Checkout = React.lazy(() => import('./pages/checkout'));

// Loading component
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary"></div>
  </div>
);

// ScrollToTop component to handle scroll behavior on route change
function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return null;
}

// Error Boundary Component
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by boundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center justify-center min-h-screen p-4">
          <div className="max-w-md text-center">
            <h2 className="text-2xl font-bold text-red-600 mb-4">Something went wrong</h2>
            <p className="text-gray-600 mb-4">{this.state.error?.message}</p>
            <button
              onClick={() => {
                this.setState({ hasError: false });
                window.location.pathname = '/';
              }}
              className="bg-primary hover:bg-primary-hover text-white font-bold py-2 px-4 rounded"
            >
              Return Home
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

function App({ initialData = { page: null, data: {} } }) {
  // Remove the page condition and pass the full initialData
  const commonProps = {
    initialData: initialData
  };

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <ScrollToTop />
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            <Route path="/" element={<Home {...commonProps} />} />
            <Route path="/about" element={<AboutUs {...commonProps} />} />
            <Route path="/help" element={<Help {...commonProps} />} />
            <Route path="/privacy" element={<Privacy {...commonProps} />} />
            <Route path="/terms" element={<Terms {...commonProps} />} />
            <Route path="/demo" element={<Demo {...commonProps} />} />
            <Route path="/referral" element={<Referral {...commonProps} />} />
            <Route path="/pricing" element={<Pricing {...commonProps} />} />
            <Route path="/catalogs" element={<Catalogs {...commonProps} />} />
            <Route path="/marketing" element={<Marketing {...commonProps} />} />
            <Route path="/conversation" element={<Conversation {...commonProps} />} />
            <Route path="/checkout" element={<Checkout {...commonProps} />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
// Modified App Component
// function App({ initialData = { page: null, data: {} } }) {
//   // const basename = process.env.NODE_ENV === 'development' ? '' : '/static/frontend';

//   return (
//     <BrowserRouter>
//       <ErrorBoundary>
//         <ScrollToTop />
//         <Suspense fallback={<LoadingFallback />}>
//           <Routes>
//             <Route 
//               path="/" 
//               element={
//                 <Home 
//                   initialData={initialData.page === 'home' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/about" 
//               element={
//                 <AboutUs 
//                   initialData={initialData.page === 'about' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/help" 
//               element={
//                 <Help 
//                   initialData={initialData.page === 'help' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/privacy" 
//               element={
//                 <Privacy 
//                   initialData={initialData.page === 'privacy' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/terms" 
//               element={
//                 <Terms 
//                   initialData={initialData.page === 'terms' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/demo" 
//               element={
//                 <Demo 
//                   initialData={initialData.page === 'demo' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/referral" 
//               element={
//                 <Referral 
//                   initialData={initialData.page === 'referral' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/pricing" 
//               element={
//                 <Pricing 
//                   initialData={initialData.page === 'pricing' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/catalogs" 
//               element={
//                 <Catalogs 
//                   initialData={initialData.page === 'catalogs' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/marketing" 
//               element={
//                 <Marketing 
//                   initialData={initialData.page === 'marketing' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/conversation" 
//               element={
//                 <Conversation 
//                   initialData={initialData.page === 'conversation' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route 
//               path="/checkout" 
//               element={
//                 <Checkout 
//                   initialData={initialData.page === 'checkout' ? initialData.data : undefined}
//                 />
//               } 
//             />
//             <Route path="*" element={<Navigate to="/" replace />} />
//           </Routes>
//         </Suspense>
//       </ErrorBoundary>
//     </BrowserRouter>
//   );
// }

// Keep your existing AnalyticsWrapper with modifications to handle initialData
const AnalyticsWrapper = ({ initialData }) => {
  useEffect(() => {
    const trackPageView = () => {
      try {
        if (window.gtag) {
          window.gtag('event', 'page_view', {
            page_path: window.location.pathname,
            page_title: document.title,
            page_location: window.location.href
          });
        }
      } catch (error) {
        console.error('Analytics error:', error);
      }
    };

    trackPageView();

    const handleRouteChange = () => {
      trackPageView();
    };

    window.addEventListener('popstate', handleRouteChange);
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, []);

  return <App initialData={initialData} />;
};

export default AnalyticsWrapper;